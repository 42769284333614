import Image from 'next/future/image';
import dayjs from 'dayjs';
import { REEBELO_TZ_NAME } from '@lambda/reebelo';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import reebeloTextIcon from '@/public/icons/reebelo-text.svg';
import { BackButtonProps } from './types';
import chevronRightIcon from '@/public/icons/menu/chevron-right.svg';
import settings from '@/settings';
import { HOLIDAYS_START_DATE } from '@/lib/holidays/constants';
import ChristmasHat from '@/public/icons/logo/christmas_hat.png';

dayjs.extend(utc);
dayjs.extend(tz);

const BackIcon = () => (
  <div className="h-[15px] w-[15px] shrink-0">
    <Image
      src={chevronRightIcon}
      height={14}
      width={14}
      alt="Expand"
      className="mt-[-1px] h-full w-full rotate-180 transition-all duration-150 ease-in-out"
    />
  </div>
);

const BackButton = ({
  history,
  onBackToMainMenuClick,
  onBackToParentClick,
  parentName,
}: BackButtonProps) => {
  const { store } = settings;
  const storeTimeZone = REEBELO_TZ_NAME[store];

  // TODO: This isn't going to work in 2024 ;)
  const isHolidaysSeason = dayjs
    .utc()
    .tz(storeTimeZone)
    .isSameOrAfter(HOLIDAYS_START_DATE);

  if (history.length === 0) {
    return (
      <div className="flex h-full w-full items-center px-5">
        <div className="relative">
          <div className="h-5">
            <Image
              src={reebeloTextIcon}
              alt="Reebelo Text Logo"
              height={30}
              width={30}
              className="h-full w-auto"
            />
          </div>
          {isHolidaysSeason && (
            <span className="absolute top-[-2px] right-[-5px] h-[25px] w-[25px] scale-x-[-1]">
              <Image
                src={ChristmasHat}
                alt="Reebelo Christmas"
                className="h-full w-full cursor-pointer object-contain"
                priority
              />
            </span>
          )}
        </div>
      </div>
    );
  }

  if (history.length === 1) {
    return (
      <button
        className="flex h-full w-full items-center gap-2 px-5 text-left outline-none hover:underline"
        onClick={onBackToMainMenuClick}
      >
        <BackIcon />
        Main Menu
      </button>
    );
  }

  return (
    <button
      className="flex h-full w-full items-center gap-x-2 px-5 text-left outline-none hover:underline"
      onClick={onBackToParentClick}
    >
      <BackIcon />
      <div className="w-full truncate">{parentName}</div>
    </button>
  );
};

export default BackButton;
