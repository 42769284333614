import Image from 'next/future/image';
import Link from 'next/link';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { REEBELO_TZ_NAME } from '@lambda/reebelo';
import settings from '@/settings';
import { CYBER_WEEK_START_DATE } from '@/lib/holidays/constants';
import { getEdgeUrl } from '@/lib/getEdgeUrl';

dayjs.extend(utc);
dayjs.extend(tz);

const CyberMonday = ({ closeMenu }: { closeMenu: () => void }) => {
  const { store } = settings;

  const storeTimeZone = REEBELO_TZ_NAME[store];

  const generateTitle = () => {
    // Starting From December 3, 2024
    if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(CYBER_WEEK_START_DATE))
      return 'Cyber Week';

    return 'Cyber Monday';
  };

  const title = generateTitle();

  return (
    <div className="px-5 py-2.5">
      <Link href="/collections/hot-deals">
        <a onClick={closeMenu} className="group">
          <div className="relative flex h-[70px] items-center justify-between gap-x-5 rounded-lg bg-gray-700 px-3 text-white">
            <div className="absolute left-0 h-full">
              <Image
                src={getEdgeUrl('images/hot-deals/menu/cyber-monday.png')}
                alt="Hot Deals"
                height={100}
                width={200}
                className="h-full w-auto rounded-l-lg rounded-tl-xl object-cover object-right"
                quality={100}
                loading="eager"
              />
            </div>
            <h3 className="relative ml-5 text-base font-bold">{title}</h3>
            <div className="shrink-0 rounded-full bg-teal-500 px-3 py-1 text-xs font-semibold text-gray-700 group-hover:underline">
              Browse Deals
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
};

export default CyberMonday;
