import { CategoryTree } from '@lambda/apis/src/category/types';
import { Dispatch, SetStateAction } from 'react';

export interface MainMenuProps {
  showMenu: boolean;
  setShowMenu: Dispatch<SetStateAction<boolean>>;
}

export enum SlideDirectionT {
  RIGHT = 'right',
  LEFT = 'left',
  NONE = '',
}

export interface ContentProps {
  history: string[];
  setHistory: Dispatch<SetStateAction<string[]>>;
  currentChildren: CategoryTree[];
  setCurrentChildren: Dispatch<SetStateAction<CategoryTree[]>>;
  currentParent: CategoryTree | null;
  setSlideDirection: Dispatch<SetStateAction<SlideDirectionT>>;
  closeMenu: () => void;
}

export interface ContentWrapperProps {
  slideDirection: SlideDirectionT;
  history: string[];
  children: JSX.Element | null;
}

export interface BackButtonProps {
  history: string[];
  onBackToMainMenuClick: () => void;
  onBackToParentClick: () => void;
  parentName: string;
}

export interface HotDealsAndTrendingProps {
  closeMenu: () => void;
}
export interface ShopByDepartmentProps {
  history: string[];
  currentParent: CategoryTree | null;
  onClick: (item: CategoryTree) => void;
  currentChildren: CategoryTree[];
  closeMenu: () => void;
}

export interface LinkItemProps {
  title: string;
  handle: string;
  icon?: any;
  isSubMenu?: boolean;
  closeMenu: () => void;
  className?: string;
}

export interface ButtonItemProps {
  title: string;
  onClick: () => void;
  history: string[];
  subMenuItem: CategoryTree[];
  closeMenu: () => void;
}

export interface SettingsProps {
  closeMenu: () => void;
}
