import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import { REEBELO_TZ_NAME } from '@lambda/reebelo';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import ChristmasHat from '@/public/icons/logo/christmas_hat.png';
import * as Icons from './Icons';
import settings from '@/settings';
import { HOLIDAYS_START_DATE } from '@/lib/holidays/constants';

dayjs.extend(utc);
dayjs.extend(tz);

export default function AccessBarLogo() {
  const { store } = settings;
  const storeTimeZone = REEBELO_TZ_NAME[store];

  // TODO: This isn't going to work in 2024 ;)
  const isHolidaysSeason = dayjs
    .utc()
    .tz(storeTimeZone)
    .isSameOrAfter(HOLIDAYS_START_DATE);

  return (
    <>
      <div className="mt-2 hidden hover:cursor-pointer lg:mr-10 lg:flex">
        <Link href="/" prefetch={false} legacyBehavior>
          <a
            aria-label="Link to Home"
            id="icon-home-desktop"
            className="relative"
          >
            {isHolidaysSeason && (
              <div className="absolute -top-3 -left-3 h-[50px] w-[50px]">
                <Image
                  src={ChristmasHat}
                  alt="Reebelo Christmas"
                  className="h-full w-full cursor-pointer"
                  priority
                  quality={100}
                />
              </div>
            )}
            <Icons.Logo />
          </a>
        </Link>
      </div>

      <div className="flex items-center justify-center hover:cursor-pointer lg:hidden">
        <Link href="/" prefetch={false} legacyBehavior>
          <a
            aria-label="Link to Home"
            id="icon-home-mobile"
            className="relative"
          >
            {isHolidaysSeason && (
              <span className="absolute -top-0 -right-2 h-[35px] w-[35px] scale-x-[-1]">
                <Image
                  src={ChristmasHat}
                  alt="Reebelo Christmas"
                  className="h-full w-full cursor-pointer object-contain"
                  priority
                />
              </span>
            )}
            <Icons.LogoMobile />
          </a>
        </Link>
      </div>
    </>
  );
}
