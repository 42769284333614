import { REEBELO_TZ_NAME } from '@lambda/reebelo';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import settings from '@/settings';
import BlackFriday from './Themes/BlackFriday';
import DefaultBanner from './Themes/DefaultBanner';
import { HotDealsProps } from './types';
import {
  BLACK_FRIDAY_START_DATE,
  BOXING_DAY_END_DATE,
  BOXING_DAY_START_DATE,
  CYBER_MONDAY_START_DATE,
  HOLIDAYS_START_DATE,
} from '@/lib/holidays/constants';
import CyberMonday from './Themes/CyberMonday';
import Holidays from './Themes/Holidays';
import BoxingDay from './Themes/BoxingDay';

dayjs.extend(utc);
dayjs.extend(tz);

const HotDeals = ({ closeMenu }: HotDealsProps) => {
  const { store } = settings;
  const storeTimeZone = REEBELO_TZ_NAME[store];

  // Starting From December 25, 2024 until December 26th
  if (
    dayjs.utc().tz(storeTimeZone).isSameOrAfter(BOXING_DAY_START_DATE) &&
    dayjs.utc().tz(storeTimeZone).isBefore(BOXING_DAY_END_DATE) &&
    ['reebelo-ca', 'reebelo-au'].includes(store)
  )
    return <BoxingDay closeMenu={closeMenu} />;

  // Starting From December 7, 2024
  if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(HOLIDAYS_START_DATE))
    return <Holidays closeMenu={closeMenu} />;

  // Starting From November 30, 2024
  if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(CYBER_MONDAY_START_DATE))
    return <CyberMonday closeMenu={closeMenu} />;

  // Starting From November 20, 2024
  if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(BLACK_FRIDAY_START_DATE))
    return <BlackFriday closeMenu={closeMenu} />;

  return <DefaultBanner closeMenu={closeMenu} />;
};

export default HotDeals;
