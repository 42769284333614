import settings from '@/settings';

export const BLACK_FRIDAY_START_DATE = '2024-11-20';
export const CYBER_MONDAY_START_DATE = '2024-11-30'; // Saturday, November 30th
export const CYBER_WEEK_START_DATE = '2024-12-03'; // Tuesday, December 3rd
export const HOLIDAYS_START_DATE = '2024-12-07'; // Saturday, December 7th
export const BOXING_DAY_START_DATE = '2024-12-25'; // Saturday, December 25th
export const BOXING_DAY_END_DATE = '2024-12-27'; // Saturday, December 26th

export const SHOULD_SHOW_HOLIDAY_RETURNS = [
  'reebelo-us',
  'reebelo-ca',
].includes(settings.store);
