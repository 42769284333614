import Image from 'next/future/image';
import Link from 'next/link';
import { getEdgeUrl } from '@/lib/getEdgeUrl';

const Holidays = ({ closeMenu }: { closeMenu: () => void }) => (
  <div className="px-5 py-2.5">
    <Link href="/collections/hot-deals">
      <a onClick={closeMenu} className="group">
        <div className="relative flex h-[70px] items-center justify-between gap-x-5 rounded-lg bg-gradient-to-r from-[#EF5863] to-[#C52237] px-3 text-white">
          <div className="absolute left-0 h-full">
            <Image
              src={getEdgeUrl('images/hot-deals/menu/holidays-v2.png')}
              alt="Hot Deals"
              height={100}
              width={200}
              className="h-full w-auto rounded-l-lg rounded-tl-xl object-cover object-right"
              quality={100}
            />
          </div>
          <h3 className="ml-5 shrink-0 text-base font-bold">Happy Holidays!</h3>
          <div className="shrink-0 rounded-full bg-white px-3 py-1 text-xs font-semibold text-[#C52237] group-hover:underline">
            Browse Deals
          </div>
        </div>
      </a>
    </Link>
  </div>
);

export default Holidays;
