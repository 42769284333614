import cn from 'classnames';
import Image from 'next/future/image';
import Link from 'next/link';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { REEBELO_TZ_NAME } from '@lambda/reebelo';
import settings from '@/settings';
import FireIcon from '@/public/icons/collection/fire.svg';
import {
  BLACK_FRIDAY_START_DATE,
  BOXING_DAY_END_DATE,
  BOXING_DAY_START_DATE,
  CYBER_MONDAY_START_DATE,
  CYBER_WEEK_START_DATE,
  HOLIDAYS_START_DATE,
} from '@/lib/holidays/constants';

dayjs.extend(utc);
dayjs.extend(tz);

const Deals = () => {
  const generateDealsMenuTitle = () => {
    const { store } = settings;
    const storeTimeZone = REEBELO_TZ_NAME[store];

    // Starting From December 25, 2024 until December 26th
    if (
      dayjs.utc().tz(storeTimeZone).isSameOrAfter(BOXING_DAY_START_DATE) &&
      dayjs.utc().tz(storeTimeZone).isBefore(BOXING_DAY_END_DATE) &&
      ['reebelo-ca', 'reebelo-au'].includes(store)
    )
      return 'Boxing Day Deals';

    // Starting From December 7, 2024
    if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(HOLIDAYS_START_DATE))
      return 'Holiday Deals';

    // Starting From December 3, 2024
    if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(CYBER_WEEK_START_DATE))
      return 'Cyber Week Deals';

    // Starting From November 30, 2024
    if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(CYBER_MONDAY_START_DATE))
      return 'Cyber Monday Deals';

    // Starting From November 20, 2024
    if (dayjs.utc().tz(storeTimeZone).isSameOrAfter(BLACK_FRIDAY_START_DATE))
      return 'Black Friday Deals';

    return 'Deals';
  };
  const dealsMenuTitle = generateDealsMenuTitle();

  return (
    <li className={cn('menu-item relative')}>
      <Link href="/collections/hot-deals">
        <a className="flex w-full px-3 py-4 pl-0 pr-6 hover:[text-shadow:0.5px_0px_0px_#000]">
          <div className="flex items-center justify-center whitespace-nowrap">
            <span className="mr-1.5">{dealsMenuTitle} </span>
            <div className="-mt-0.5 h-4 w-4">
              <Image priority src={FireIcon} alt="Deal" />
            </div>
          </div>
        </a>
      </Link>
    </li>
  );
};

export default Deals;
